html {
  height: 100vh;
}
body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif, 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button:focus {
  outline: none;
}
@media screen and (max-width: 600px) {
  .MuiContainer-root {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
.menuItem a {
  color: '#53318a';
  text-decoration: none;
  padding: 0;
  height: 50px;
  width: auto;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.menuItem {
  width: auto;
  color: '#53318a';
  text-decoration: none;

  display: flex;
  align-items: center;
  flex-direction: row;
}

.menuItem a:hover {
  color: '#53318a';
  text-decoration: none;
}

.menuItem a:disabled {
  color: '#53318a';
  text-decoration: none;
  opacity: 1;
}

.MuiListItem-root.Mui-disabled {
  opacity: 1;
}
